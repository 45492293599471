// import { useEffect, useState } from 'react';
// import { motion } from 'framer-motion';
// import styles from './style.module.scss'; // Ensure SCSS is processed correctly
// import { opacity, slideUp } from './anim'; // Ensure these animations are defined correctly

// const words = [
//     "Welcome to Cafe Valentino",         // English
//     "Bienvenue au Café Valentino",       // French
//     "Benvenuti al Caffè Valentino",      // Italian
//     "Bem-vindo ao Café Valentino",       // Portuguese
//     "カフェ・ヴァレンティーノへようこそ", // Japanese
//     "Välkommen till Café Valentino",     // Swedish
//     "Willkommen im Café Valentino",      // German
//     "Welkom bij Café Valentino"          // Dutch
//   ];
  
// export default function Index() {
//     const [index, setIndex] = useState(0);
//     const [dimension, setDimension] = useState({ width: 0, height: 0 });

//     useEffect( () => {
//         setDimension({width: window.innerWidth, height: window.innerHeight})
//     }, [])


//    useEffect( () => {
//         if(index === words.length - 1) return;
//         setTimeout( () => {
//             setIndex(index + 1)
//         }, index === 0 ? 1000 : 350)
//     }, [index])

//     const initialPath = `M0 0 L${dimension.width} 0 L${dimension.width} ${dimension.height} Q${dimension.width / 2} ${dimension.height + 300} 0 ${dimension.height} L0 0`;
//     const targetPath = `M0 0 L${dimension.width} 0 L${dimension.width} ${dimension.height} Q${dimension.width / 2} ${dimension.height} 0 ${dimension.height} L0 0`;

//     const curve = {
//         initial: {
//             d: initialPath,
//             transition: { duration: 0.9, ease: [0.76, 0, 0.24, 1] },
//         },
//         exit: {
//             d: targetPath,
//             transition: { duration: 0.3, ease: [0.76, 0, 0.24, 1], delay: 0.4 },
//         },
//     };

//     return (
//         <motion.div variants={slideUp} initial="initial" exit="exit" className={styles.introduction}>
//             {dimension.width > 0 && (
//                 <>
//                     <motion.p variants={opacity} initial="initial" animate="enter">
//                         <span></span>{words[index]}
//                     </motion.p>
//                     <svg>
//                         <motion.path variants={curve} initial="initial" exit="exit"></motion.path>
//                     </svg>
//                 </>
//             )}
//         </motion.div>
//     );
// }
import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import styles from './style.module.scss'; // Ensure SCSS is processed correctly
import { opacity, slideUp } from './anim'; // Ensure these animations are defined correctly

const words = [
    "Welcome to Cafe Valentino",         // English
    "Bienvenue au Café Valentino",       // French
    "Benvenuti al Caffè Valentino",      // Italian
    "Bem-vindo ao Café Valentino",       // Portuguese
    "カフェ・ヴァレンティーノへようこそ", // Japanese
    "Välkommen till Café Valentino",     // Swedish
    "Willkommen im Café Valentino",      // German
    "Welkom bij Café Valentino"          // Dutch
  ];

export default function Index() {
    const [index, setIndex] = useState(0);
    const [dimension, setDimension] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const updateDimensions = () => {
            setDimension({ width: window.innerWidth, height: window.innerHeight });
        };
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    useEffect(() => {
        if (index === words.length - 1) return;
        const timer = setTimeout(() => {
            setIndex(index + 1);
        }, index === 0 ? 1000 : 350);
        return () => clearTimeout(timer);
    }, [index]);

    const initialPath = `M0 0 L${dimension.width} 0 L${dimension.width} ${dimension.height} Q${dimension.width / 2} ${dimension.height + 300} 0 ${dimension.height} L0 0`;
    const targetPath = `M0 0 L${dimension.width} 0 L${dimension.width} ${dimension.height} Q${dimension.width / 2} ${dimension.height} 0 ${dimension.height} L0 0`;

    const curve = {
        initial: {
            d: initialPath,
            transition: { duration: 0.9, ease: [0.76, 0, 0.24, 1] },
        },
        exit: {
            d: targetPath,
            transition: { duration: 0.3, ease: [0.76, 0, 0.24, 1], delay: 0.4 },
        },
    };

    return (
        <motion.div variants={slideUp} initial="initial" exit="exit" className={styles.introduction}>
            {dimension.width > 0 && (
                <>
                    <motion.p variants={opacity} initial="initial" animate="enter">
                        <span></span>{words[index]}
                    </motion.p>
                    <svg>
                        <motion.path variants={curve} initial="initial" exit="exit"></motion.path>
                    </svg>
                </>
            )}
        </motion.div>
    );
}
