import React from 'react';
import { motion, useTransform } from 'framer-motion';
import styles from './style.module.scss';
import ButtonWrapper from '../Button';

// Import your images
import image1 from '../../images/hand.jpeg';
import image2 from '../../images/IMG_7418.jpg';

// Import PDF files
// import pdfFoodMenu from '../../images/foodMenu.pdf';
// import pdfDrinksMenu from '../../images/drinksMenu.pdf';

// Map images to src values
const imageMap = {
  'hand.jpeg': image1,
  'IMG_7418.jpg': image2,
  // Add more mappings as needed
};

const Card = ({ i, title, description, src, color, progress, range, targetScale, link }) => {
  // Ensure image is correctly mapped
  const imageSrc = imageMap[src];

  // Calculate scale transformations
  const scale = useTransform(progress, range, [1, targetScale]);

  return (
    <div className={styles.cardContainer}>
      <motion.div 
        style={{ backgroundColor: color, scale, top: `calc(-5vh + ${i * -200}px)` }} 
        className={styles.card}
      >
        <h2>{title}</h2>
        <div className={styles.body}>
          <div className={styles.description}>
            <p>{description}</p>
            <span>
              <ButtonWrapper link={link} />
            </span>
          </div>
          <div className={styles.imageContainer}>
            <motion.div className={styles.inner}>
              <img
                src={imageSrc} 
                alt={title} 
              />
            </motion.div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Card;