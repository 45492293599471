import React from 'react'
import Content from './Content';





export default function aboutUs() {
  return (
    <div 
        className='relative h-[800px]'
        style={{clipPath: "polygon(0% 0, 100% 0%, 100% 100%, 0 100%)"}}
    >
        <div className='relative h-[calc(100vh+800px)] -top-[100vh]'>
            <div className='h-[800px] sticky top-[calc(100vh-800px)]'>
            {/* <div style={{ height: "50vh", backgroundColor: "black" }}></div>
        <Character paragraph={paragraph} />
        <div style={{ height: "20vh", backgroundColor: "black" }}></div>  */}
                <Content />
            </div>
        </div>
    </div>
  )
}
