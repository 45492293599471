import pdfFoodMenu from '../../images/foodMenu.pdf';
import pdfDrinksMenu from '../../images/drinkMenu.pdf';
export const projects = [
  {
    title: "Our Food Menu",
    description: "Explore our curated selection of delectable dishes, designed to satisfy every craving",
    src: "hand.jpeg",
    link: pdfFoodMenu,
    color: "#9c9393"
  },
  {
    title: "Our Drinks Menu",
    description: "Indulge in our diverse array of beverages, from expertly brewed coffees and teas",
    src: "IMG_7418.jpg",
    link: pdfDrinksMenu,
    color: "#939c93"
  }
];