import React from 'react';

export default function Content2() {
  return (
    <div className='bg-[#8f8f93] py-8 px-4 sm:px-8 lg:px-12 h-full w-full flex flex-col justify-between'>
        {/* <Section1 /> */}
        <Section2 />
    </div>
  );
}

// const Section1 = () => {
//     return (
//         <div className='mb-12 lg:mb-0 pt-28 lg:pt-10'>
//             <Nav />
//         </div>
//     );
const Section2 = () => {
    return (
      <div className='flex flex-col md:flex-row justify-between items-center md:items-end mt-16 md:mt-8 px-4'>
        <h1 
          className={`
            text-[9.5vw] sm:text-[11vw] md:text-[5vw] lg:text-[4.5vw] leading-[1.5] text-center md:text-left
            pt-[7rem] // Default for smaller devices (phones)
            sm:pt-[7rem] // Small devices (phones)
            md:pt-[5rem] // Medium devices (tablets)
            lg:pt-[26rem] // Large devices (laptops)
            xl:pt-[15rem] // Extra-large devices (standard desktops)
            2xl:pt-[13rem] // Larger desktops (2xl screens)
          `}
        >
          Step into Cafe Valentino, nestled in the center of Bramhall. Enjoy our artisanal coffee, delectable baked goods, and a diverse menu of mouthwatering dishes.
        </h1>
      </div>
    );
  }

// const Nav = () => {
//     return (
//         <div className='flex flex-col lg:flex-row flex-wrap gap-8 lg:gap-12'>
//            "Step into Cafe Valentino, nestled in the center of Bramhall. Enjoy our artisanal coffee, delectable baked goods, and a diverse menu of mouthwatering dishes."
//         </div>
//     );
// }