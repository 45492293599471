import React, { useRef } from 'react';
import { useScroll, motion } from 'framer-motion';
import Card from './Cards'; // Adjust path as needed
import { projects } from './data'; // Adjust path as needed

const ParentComponent = () => {
  const container = useRef(null);
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ['start start', 'end end']
  });

  return (
    <div ref={container}>
      {projects.map((project, i) => {
        const targetScale = 1 - ((projects.length - i) * 0.05);
        return (
          <Card
            key={`p_${i}`}
            i={i}
            {...project}
            progress={scrollYProgress} // Pass the motion value here
            range={[i * 0.25, 1]}
            targetScale={targetScale}
          />
        );
        
      })}
    </div>
  );
};

export default ParentComponent;
