import React from "react";
import img1 from '../images/brew.jpg'; // Adjust the path as needed
import img2 from '../images/justEat.jpg'; // Adjust the path as needed
import img3 from '../images/onlinestore.jpg';

export default () => {

    const posts = [
        {
            title: "WE ARE ON JUST EAT!",
            desc: "We are offically on Just Eat so you can now enjoy your favorite treats from the comfort of your home. Whether it's a cozy breakfast, a tasty lunch, or an afternoon pick-me-up, it's just a few clicks away. Order now on Just Eat and let us take care of the rest! ",
            img: img2,
            date: "August 10 2024"
        },
        {
            title: "Exciting news: Our online Store Is Coming Soon!",
            desc: "We are thrilled to announce that Cafe Valentino is launching an online store! Soon, you will be able to order your favorite coffee beans, essential brewing equipment, and exclusive café accessories",
            img: img3,
            date: "June 17 2024"
        },
        {
            title: "Something New Is Brewing Up",
            desc: "We have been working on a top-secret addition to our menu that is set to surprise and delight! While we can`t spill the beans just yet, we promise this new creation is unlike anything you have tasted before. Stay tuned for the big reveal! 👀",
            img: img1,
            date: "April 10 2024"
        }
    ];

    return (
        <section className="mt-12 mx-auto px-4 max-w-screen-xl md:px-8 bg-black">
            <div className="text-center">
                <h1 className="text-3xl text-white font-semibold">
                    Latest News
                </h1>
                <p className="mt-3 text-gray-300">
                    Don't Forget To Check In Often To Keep Up With Our Latest Updates.
                </p>
            </div>
            <div className="mt-12 grid gap-2 sm:grid-cols-2 lg:grid-cols-3">
                {
                    posts.map((item, key) => (
                        <article className="max-w-md mx-auto mt-4 shadow-lg border border-gray-700 rounded-md duration-300 hover:shadow-sm bg-gray-900" key={key}>
                            <div className="relative w-full h-48">
                                <img 
                                    src={item.img} 
                                    loading="lazy" 
                                    alt={item.title} 
                                    className="absolute inset-0 w-full h-full object-cover rounded-t-md" 
                                />
                            </div>
                            <div className="pt-3 ml-4 mr-2 mb-3">
                                <h3 className="text-xl text-white">
                                    {item.title}
                                </h3>
                                <p className="text-gray-400 text-sm mt-1">{item.desc}</p>
                                <span className="block text-gray-400 text-sm mt-2">{item.date}</span>
                            </div>
                        </article>
                    ))
                }
            </div>
        </section>
    )
};
