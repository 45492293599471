import React, { useEffect, useRef } from "react";
import { useScroll } from 'framer-motion';
// import { TypewriterEffectDemo } from "./TypeWriterEffectDemo";
// import Home from "./App/Mask";

export default function Page() {
    const container = useRef();
    const paths = useRef([]);
    const { scrollYProgress } = useScroll({
        target: container,
        offset: ['start end', 'end end']
    });

    useEffect(() => {
        scrollYProgress.on("change", e => {
            paths.current.forEach((path, i) => {
                path.setAttribute("startOffset", -40 + (i * 40) + (e * 40) + "%");
            });
        });
    }, [scrollYProgress]);

    return (
        <div ref={container} className="relative bg-black">
           {/* <TypewriterEffectDemo /> */}
            <svg className="w-full mb-10" viewBox="0 0 250 90">
                <path fill="none" id="curve" d="m0,88.5c61.37,0,61.5-68,126.5-68,58,0,51,68,123,68"/>
                <text className="text-[19px] sm:text-[18px] uppercase" style={{ fill: "#8f8f93" }}>
                    {
                        [...Array(3)].map((_, i) => (
                            <textPath key={i} ref={ref => paths.current[i] = ref} startOffset={i * 40 + "%"} href="#curve">
                            Our Story
                            </textPath>
                        ))
                    }
                </text>
            </svg>
            <section className="h-screen bg-black flex justify-center items-center top-4">
                {/* <Home /> */}
            </section>
        </div>
    );
}
