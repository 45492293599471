import React from 'react';


export default function Content2() {
  return (
    <div className='bg-[#8f8f93] py-12 px-4 md:px-12 h-full w-full flex flex-col justify-between'>
        {/* <Section1 /> */}
        <Section2 />
    </div>
  );
}

const Section2 = () => {
  return (
    <div className='flex flex-col md:flex-row justify-between items-center md:items-end mt-16 md:mt-8 px-4'>
      <h1 
        className={`
          text-[6vw] sm:text-[6.5vw] md:text-[4vw] lg:text-[3.5vw] leading-[1.4] text-center md:text-left
          mt-[1rem] pt-[25rem] // Default for smaller screens
          sm:pt-[8rem] // Small devices (phones)
          md:pt-[10rem] // Medium devices (tablets)
          lg:pt-[20rem] // Large devices (laptops)
          xl:pt-[26rem] // Extra-large devices (standard desktops)
          2xl:pt-[13rem] // Larger desktops (2xl screens)
        `}
      >
        Nestled in the heart of Bramhall, Café Valentino is more than just a café—it's a story of passion, family, and community. Our café is a tribute to the timeless traditions of hospitality and culinary excellence. At Café Valentino, our mission is to provide a welcoming atmosphere where the aroma of freshly brewed coffee and the taste of homemade delicacies make you feel right at home. We are dedicated to using only the finest ingredients, sourced locally whenever possible.
      </h1>
    </div>
  );
}

