import React from 'react';
import img1 from "../../images/tiktok.svg";
import img2 from "../../images/instagram.svg";

export default function Content() {
  return (
    <div className='bg-[#8f8f93] py-8 px-4 sm:px-8 lg:px-12 h-full w-full flex flex-col justify-between'>
        <Section1 />
        <Section2 />
    </div>
  );
}

const Section1 = () => {
  return (
    <div 
      className={` 
        mb-12 md:mb-0 
        pt-[33rem] // Adjusted padding for smaller devices
        sm:pt-[20rem] // Reduced padding for phones
        md:pt-[30rem] // Padding for tablets
        lg:pt-[0rem] // Padding for laptops
        xl:pt-[35rem] // Padding for larger desktops
        2xl:pt-[20rem] // Padding for extra-large screens
      `}
    >
      <Nav />
    </div>
  );
}

const Section2 = () => {
    return (
      <div className='flex flex-col md:flex-row justify-between items-center md:items-end mt-8 md:mt-0'>
        <h1 
          className={`
            text-[20vw] md:text-[12vw] lg:text-[14vw] leading-[0.8] text-center md:text-left text-[#000000]
            mt-[1rem]
            pt-[0] // Default padding
            sm:pt-[1rem] // Add some padding for small devices
            md:pt-[0.5rem] // Push up slightly for tablets
            lg:pt-[0rem] // Padding for laptops
            xl:pt-[0] // Reset padding for desktops
            flex flex-col
          `}
        >
          <span>Cafe</span>
          <span>Valentino</span>
        </h1>
      </div>
    );
  }
  

const Nav = () => {
  return (
    <div className='flex flex-col md:flex-row flex-wrap gap-8 md:gap-12'>
        <div className='flex flex-col gap-2 w-full md:w-1/3'>
            <h3 className='mb-2 uppercase text-[#000000] text-lg md:text-xl'>Opening Times</h3>
            <p className='text-sm md:text-base text-[#000000]'>Monday - Friday: 8 till 5</p>
            <p className='text-sm md:text-base text-[#000000]'>Saturday: 9 till 5</p>
            <p className='text-sm md:text-base text-[#000000]'>Sunday: 9 till 4</p>
            <h3 className='mb-2 uppercase text-[#000000] text-lg md:text-xl'>Find Us</h3>
            <p className='text-sm md:text-base text-[#000000]'>22 Bramhall Ln S, Bramhall, Stockport SK7 1AF</p>
        </div>
        <div className='flex flex-col gap-4 w-full md:w-1/3'>
            <h3 className='mb-2 uppercase text-[#000000] text-lg md:text-xl'>Contact Us</h3>
            <p className='text-sm md:text-base text-[#000000]'>Email: cafevalentino@gmail.com</p>
            <p className='text-sm md:text-base text-[#000000]'>Phone: 0161 383 6234</p>
            <div className='flex gap-4 mt-2 '>
                <a href="https://www.tiktok.com/discover/Cafe-valentino-bramhall" target="_blank" rel="noopener noreferrer">
                    <img src={img1} alt="TikTok" className='w-6 h-6 md:w-8 md:h-8 filter invert' />
                </a>
                <a href="https://www.instagram.com/_cafevalentino/" target="_blank" rel="noopener noreferrer">
                    <img src={img2} alt="Instagram" className='w-6 h-6 md:w-8 md:h-8 filter invert' />
                </a>
            </div>
        </div>
    </div>
  );
}
