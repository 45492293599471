import React, { useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { TextParallaxContentExample2 } from './components/TextParallexSec';
import { TypewriterEffectDemo } from './components/TypeWriterEffectDemo';
import Preloader from './components/Preloader';
import Character from './components/char/Character';
import Lenis from '@studio-freight/lenis';
import ParentComponent from './components/Cards/ParentComponent';
import Page from './components/Scroll';
import Footer from './components/Footer/Footer1';
import Header from './components/nav/head'; // Import the Header component
import AboutUs from './components/Footer copy/Footer1'
import StoryUs from './components/Footer copy 2/Footer1'
import Blog from './components/blog';


const paragraph = "Step into Cafe Valentino, nestled in the center of Bramhall. Enjoy our artisanal coffee, delectable baked goods, and a diverse menu of mouthwatering dishes.";
const paragraph2 = "Nestled in the heart of Bramhall, Café Valentino is more than just a café—it's a story of passion, family, and community. Our café is a tribute to the timeless traditions of hospitality and culinary excellence. At Café Valentino, our mission is to provide a welcoming atmosphere where the aroma of freshly brewed coffee and the taste of homemade delicacies make you feel right at home. We are dedicated to using only the finest ingredients, sourced locally whenever possible, to create dishes that are both delicious and nourishing.";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
      document.body.style.cursor = 'default';
      window.scrollTo(0, 0);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const lenis = new Lenis();

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);

    return () => {
      lenis.destroy();
    };
  }, []);

  return (
    <main>
      <AnimatePresence mode="wait">
        {isLoading && <Preloader />}
      </AnimatePresence>

      <Header /> {/* Use Header here */}

      {/* <SlideTabsExample /> */}
      <div className="bg-black">
        <section id='home'>
        <TextParallaxContentExample2 />
        </section>
        <AboutUs />
{/* 
        <div style={{ height: "5vh", backgroundColor: "black" }}></div>
        <Character paragraph={paragraph} />
        <div style={{ height: "20vh", backgroundColor: "black" }}></div> */}
        <section id='menu'> 
        <TypewriterEffectDemo />
        </section>
        <ParentComponent />

        <section id='ourStory'>
        <Page />

        </section>
        {/* <AboutUs /> */}
        <StoryUs />
        {/* <Blog /> */}
        <section id='news'>


        <div style={{ height: "10vh", backgroundColor: "black" }}></div>
        <Blog style={{backgroundColor:"black"}}/>
        <div style={{ height: "50vh", backgroundColor: "black" }}></div>
        </section>
        <section id='contactUs'>
        <Footer />

        </section>
      </div>
    </main>
  );
}

export default App;

