import React from "react";

const Button = ({ link }) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer" className="grid min-h-[50px] place-content-center p-2">
      <DrawOutlineButton className="font-extrabold">Menu</DrawOutlineButton>
    </a>
  );
};


const DrawOutlineButton = ({ children, ...rest }) => {
  return (
    <button
      {...rest}
      className="group relative px-4 py-1 font-extrabold text-slate-100 transition-colors duration-[400ms] hover:text-indigo-300"
      style={{fontSize: '24px'}}  
    >
      <span className="relative z-10 text-shadow-lg tracking-widest">{children}</span>

      {/* TOP */}
      <span className="absolute left-0 top-0 h-[2px] w-0 bg-indigo-300 transition-all duration-100 group-hover:w-full" />

      {/* RIGHT */}
      <span className="absolute right-0 top-0 h-0 w-[2px] bg-indigo-300 transition-all delay-100 duration-100 group-hover:h-full" />

      {/* BOTTOM */}
      <span className="absolute bottom-0 right-0 h-[2px] w-0 bg-indigo-300 transition-all delay-200 duration-100 group-hover:w-full" />

      {/* LEFT */}
      <span className="absolute bottom-0 left-0 h-0 w-[2px] bg-indigo-300 transition-all delay-300 duration-100 group-hover:h-full" />
    </button>
  );
};

export default Button;
