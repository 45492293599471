import React, { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import fallbackImage from "../images/homePicSmall.JPG"; // Import the image to use as the background

const IMG_PADDING = 0;

const StickyImage = ({ imageUrl }) => {
  const targetRef = useRef(null);

  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["end end", "end start"],
  });

  return (
    <motion.div
      style={{
        top: IMG_PADDING,
      }}
      ref={targetRef}
      className="sticky z-0 overflow-hidden h-screen sm:h-[80vh] md:h-[100vh]" // Added responsive height classes
    >
      <motion.img
        className="absolute inset-0 w-full h-full object-cover object-center"
        style={{
          filter: "blur(2px)", // Adjusted blur effect to be more subtle
        }}
        src={imageUrl}
        alt="Background"
      />
    </motion.div>
  );
};

const OverlayCopy = ({ subheading, heading }) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end start"],
  });

  const y = useTransform(scrollYProgress, [0, 1], [250, -250]);
  const opacity = useTransform(scrollYProgress, [0.25, 0.5, 0.75], [0, 1, 0]);

  return (
    <motion.div
      style={{
        y,
        opacity,
      }}
      ref={targetRef}
      className="absolute left-0 top-0 flex h-screen w-full flex-col items-center justify-center text-white px-4 sm:h-[80vh] md:h-[100vh]" // Added responsive height classes
    >
      <p className="mb-2 text-center text-xl md:mb-4 md:text-3xl">
        {subheading}
      </p>
      <p className="text-center text-4xl font-bold md:text-7xl">{heading}</p>
    </motion.div>
  );
};

const TextParallaxContent = ({ imageUrl, subheading, heading, children }) => {
  return (
    <div
      style={{
        paddingLeft: IMG_PADDING,
        paddingRight: IMG_PADDING,
        backgroundColor: "black",
      }}
    >
      <div className="relative h-[150vh] sm:h-[130vh] md:h-[150vh]"> {/* Adjusted overall height */}
        <StickyImage imageUrl={imageUrl} />
        <OverlayCopy heading={heading} subheading={subheading} />
      </div>
      {children}
    </div>
  );
};

export const TextParallaxContentExample2 = () => {
  return (
    <div className="bg-white">
      <TextParallaxContent
        imageUrl={fallbackImage}
        subheading="Cafe Valentino"
        heading="Where Every Cup Is A Story"
      />
    </div>
  );
};

export default TextParallaxContentExample2;
