
import React from 'react';
import Content from './Content';

export default function storyUs() {
  return (
    <div 
        className='relative h-[800px]' 
        style={{clipPath: "polygon(0% 0, 100% 0%, 100% 100%, 0 100%)"}}
    >
        <div className='relative h-[calc(100vh+1200px)] -top-[100vh]'>
            <div className='h-[1200px] sticky top-[calc(100vh-1200px)]'> 
                <Content />
            </div>
        </div>
    </div>
  );
}