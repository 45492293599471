// import React, { useEffect, useState } from 'react';
// import styles from './styleHead.module.scss'; // Adjust the path as necessary
// import Nav from './index'; // Adjust the path as necessary
// import { AnimatePresence } from 'framer-motion';

// const Header = () => {
//   const [isActive, setIsActive] = useState(false);

//   // Close the nav when the pathname changes (for example, if you were using routing)
//   useEffect(() => {
//     if (isActive) setIsActive(false);
//   }, []);

//   return (
//     <>
//       <div className={styles.main}>
//         <div className={styles.header}>
//           <div onClick={() => setIsActive(!isActive)} className={styles.button}>
//             <div className={`${styles.burger} ${isActive ? styles.burgerActive : ''}`}></div>
//           </div>
//         </div>
//       </div>
//       <AnimatePresence mode="wait">
//         {isActive && <Nav />}
//       </AnimatePresence>
//     </>
//   );
// };

// export default Header;
import React, { useState } from 'react';
import styles from './styleHead.module.scss'; // Adjust path as needed
import Nav from './index'; // Adjust path as needed
import { AnimatePresence } from 'framer-motion';

const Header = () => {
  const [isActive, setIsActive] = useState(false);

  const handleButtonClick = () => {
    setIsActive(prev => !prev);
  };

  return (
    <>
      <div className={styles.header}>
        <div onClick={handleButtonClick} className={styles.button}>
          <div className={`${styles.burger} ${isActive ? styles.burgerActive : ''}`}></div>
        </div>
      </div>
      <AnimatePresence>
        {isActive && <Nav isActive={isActive} onClose={() => setIsActive(false)} />}
      </AnimatePresence>
    </>
  );
};

export default Header;
