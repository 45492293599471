import { TypewriterEffect } from "../components/TypeWriterEffect";

export function TypewriterEffectDemo() {
  const words = [
    {
      text: "Come",
      className: " text-white"
    },
    {
      text: "Explore",
      className: " text-white"
    },
    {
      text: "Our",
      className: " text-white"
    },
    {
      text: "Menu.",
      className: "",
      style: { color: "grey" } 
    },
  ];

  return (
    <div className="flex flex-col items-center justify-center h-[4rem] pt-4 bg-black ">
      <TypewriterEffect
        words={words}
        className="text-center text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl"
      />
    </div>
  );
}
