
// // export default Nav;
// import React from 'react';
// import { AnimatePresence, motion } from 'framer-motion';
// import { menuSlide } from '../nav/Anime/anim'; // Ensure correct path
// import styles from './styleNav.module.scss'; // Ensure correct path

// const navItems = [
//   { title: 'Home', href: '#home' },
//   { title: 'Work', href: '#work' },
//   { title: 'About', href: '#about' },
//   { title: 'Contact', href: '#contact' },
// ];

// const Nav = ({ isActive, onClose }) => {
//   return (
//     <AnimatePresence>
//       {isActive && (
//         <motion.div
//           className={styles.menu}
//           variants={menuSlide}
//           initial="initial"
//           animate="enter"
//           exit="exit"
//         >
//           <svg className={styles.svgCurve}>
//             {/* SVG path rendering */}
//           </svg>
//           <div className={styles.navContent}>
//             {navItems.map((item, index) => (
//               <a
//                 key={index}
//                 href={item.href}
//                 onClick={(e) => {
//                   e.preventDefault();
//                   onClose(); // Close the nav on link click
//                   const element = document.querySelector(item.href);
//                   if (element) {
//                     element.scrollIntoView({ behavior: 'smooth' });
//                   }
//                 }}
//                 className={styles.navLink}
//               >
//                 {item.title}
//               </a>
//             ))}
//           </div>
//         </motion.div>
//       )}
//     </AnimatePresence>
//   );
// };
import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { menuSlide } from '../nav/Anime/anim'; // Ensure correct path
import styles from './styleNav.module.scss'; // Ensure correct path

const navItems = [
  { title: 'Home', href: '#home' },
  { title: 'Menu', href: '#menu' },
  { title: 'Our Story', href: '#ourStory' },
  { title: 'Latest News', href: '#news' },
  { title: 'Contact Us', href: '#contactUs' },
];

const Nav = ({ isActive, onClose }) => {
  return (
    <AnimatePresence>
      {isActive && (
        <motion.div
          className={styles.menu}
          variants={menuSlide}
          initial="initial"
          animate="enter"
          exit="exit"
        >
          <svg className={styles.svgCurve}>
            {/* SVG path rendering */}
          </svg>
          <div className={styles.body}>
            <div className={styles.nav}>
              <div className={styles.header}>Cafe Valentino</div>
              {navItems.map((item, index) => (
                <a
                  key={index}
                  href={item.href}
                  onClick={(e) => {
                    e.preventDefault();
                    onClose(); // Close the nav on link click
                    const element = document.querySelector(item.href);
                    if (element) {
                      element.scrollIntoView({ behavior: 'smooth' });
                    }
                  }}
                  className={styles.navLink}
                >
                  {item.title}
                </a>
              ))}
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Nav;
